/* You can add global styles to this file, and also import other style files */
@import "primeng.styles";

:root {
  --callidus-blue: #0F6EBE;
  --callidus-dark-blue: #032856;
  --callidus-dark-red: #a10000;
  --callidus-yellow: #fff497;
  --callidus-green: #b8fcbd;
  --callidus-red: #fd9797;
  --callidus-dark-gray: #41505a;
  --callidus-light-gray: #c8c8d2;
  --callidus-gray: #A8A8B0;
}

.btn.btn-sm.btn-primary {
  background: var(--callidus-blue);
  border-color: var(--callidus-blue);
  border-radius: 20px;
  padding: 8px 20px;
  font-weight: 500;
  margin: 0 .75rem;

  &:hover {
    background: var(--callidus-dark-blue);
    border-color: var(--callidus-dark-blue);
  }
}

.btn.btn-sm.btn-secondary {
  background: var(--callidus-gray);
  border-color: var(--callidus-gray);
  border-radius: 20px;
  padding: 8px 20px;
  font-weight: 500;
  margin: 0 .75rem;

  &:hover {
    background: var(--callidus-dark-gray);
    border-color: var(--callidus-dark-gray);
  }
}

.form-check-input:checked {
  background-color: var(--callidus-dark-blue) !important;
  border-color: var(--callidus-dark-blue) !important;
}

.pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0 0 16px #dadada;
}

h1,
h2,
h3 {
  color: var(--callidus-dark-blue) !important;
}


.green {
  background-color: var(--callidus-green);
}

.yellow {
  background-color: var(--callidus-yellow);
}

.red {
  background-color: var(--callidus-red);
}

p-overlaypanel {
  z-index: 2500;
}
