// override primeng input number component styles to match with the bootstrap styles
p-inputnumber,
.p-inputnumber.p-component {
  width: 100%;
}

.p-inputtext {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  min-height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: .875rem !important;
  border-radius: 0.25rem;

  &:enabled:hover {
    border-color: #ced4da !important;
  }
}

p-inputnumber.p-element.p-inputwrapper.ng-invalid.ng-touched {
  border: 1px solid var(--error-text-color);
  border-radius: 4px;
  box-shadow: 0 0 0 0.2rem var(--error-box-shadow-1);

  :focus {
    box-shadow: 0 0 0 0.2rem var(--error-box-shadow-2);
  }
}


p-inputnumber.p-element.p-inputwrapper.ng-invalid.ng-touched + .invalid-feedback,
p-multiselect.p-element.p-inputwrapper.ng-invalid.ng-touched + .invalid-feedback {
  display: block;
}

p-multiselect {
  .p-multiselect > .p-multiselect-label-container > .p-multiselect-label {
    padding: 4px 4px 4px 8px;
    font-size: 14px;
  }

  .p-multiselect > .p-multiselect-label-container > .p-multiselect-label:not(.p-placeholder) {
    color: #495057;
  }

  .p-multiselect-header {
    display: none !important;
  }

  ul {
    padding: 0
  }
}

.input-centered {
  input {
    text-align: center;
  }
}

/*P-Calendar does not support hiding Years anymore, so we have to manually hide Years and "Next" and "Previous" Buttons*/
.p-datepicker-year, .p-datepicker-next, .p-datepicker-prev {
  display: none !important;
}

p-calendar[styleclass="year-selection-hidden"] {
  border: none !important;
  padding: 0 !important;

  span {
    width: 100%;

    input {
      border: var(--bs-border-width) solid var(--bs-border-color) !important;
    }
  }
}
